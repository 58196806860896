.swipperContainer1 .swiper {
    width: 100%;


  }
  
  .swipperContainer1 .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 40vw;
    height: 70vh;
  }
  
  .swipperContainer1 .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
