.swipperContainer2 .swiper {
    width: 100%;
    height: 70vh;
  }
  
.swipperContainer2 .swiper-slide {
position: relative;
width: 100%;
height: 60vh;

/* Center slide text vertically */
display: flex;
justify-content: center;
align-items: center;
}
  
.swipperContainer2 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet{
  width: 8px;
  height: 8px;
}